import React, { Suspense, lazy } from "react";
import { useLocation } from "react-router-dom";
import Loading from "./components/Loding";

const AppAuthenticated = lazy(async () => import("./AppAuthenticated"));
const AppUnauthenticated = lazy(async () => import("./AppUnauthenticated"));

const App: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Suspense fallback={<Loading />}>
      {pathname === "/login" || pathname === "/forgot-password" ? (
        <AppUnauthenticated />
      ) : (
        <AppAuthenticated />
      )}
    </Suspense>
  );
};

export default App;
