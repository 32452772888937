// CustomTheme.jsx

import { createTheme } from "@mui/material";

// Define your custom theme
const Theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    text: {
      primary: "#000",
    },
  },
  typography: {
    fontFamily: [
      "Poppins", // Default Material-UI font
      "Poppins", // Additional fallback font
      "sans-serif", // Fallback generic font
    ].join(","),
  },
});

export default Theme;
