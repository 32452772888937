import { Helmet, HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import Theme from "./components/Theme";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import React from "react";
import { ThemeProvider } from "@mui/material";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

interface AppProvidersProps {
  children: React.ReactNode;
}

export const AppProviders: React.FC<AppProvidersProps> = ({ children }) => (
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={Theme}>
        <Helmet titleTemplate="%s - VPS" defaultTitle="VPS" />
        <BrowserRouter>
          <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  </HelmetProvider>
);
